/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.form-logo {
    width: 80%;
    height: auto;
}

.logo-container {
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    align-items: flex-end;
    display: flex;
}

@media only screen and (max-width: 576px) {
    .form-logo {
        width: 40%;
        height: auto;
        margin: 0 auto;
    }

    .form-title {
        font-size: 25px;
    }
}


.form-title {
    font-size: 30px;
}

.form-container {
    padding-top: 20px;
}


.sub-title {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
}

.row-border {
    padding: 10px;
    border: 1px solid;
}

.row-border + .row-border {
    border-top: 0;
}

.legend {
    padding-top: 10px;
    padding-bottom: 10px;
}
.report-text-name {
    font-size: 20px;
   
}
.vertical-center {
    margin: auto 0;
}
.report-text {
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.report-title {
    font-size: 18px;
}
.label-custom {
    font-size:16px;
    font-weight:bold;
}

.list-grid>td {
    vertical-align:middle;
}
.dot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}
.submit-covid {
    font-size:.7rem;
    font-weight:bold;
}
.temperature-screening {
    font-weight:bold;
    display:block;
    padding-top:10px;
    font-size:18px;

}
.green-dot {
    height: 25px;
    width: 25px;
    background-color: #00FF00;
    border-radius: 50%;
    display: inline-block;
}